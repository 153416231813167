<template>
    <div class="account-settings" v-if="active === 0">
        <div v-if="inImageUploading" class="loading-overlay">
            <div class="loading-spinner"></div>
        </div>
        <div class="max-w-screen-md mx-auto p-6">
            <div class="flex flex-col space-y-4">
                <div>
                    <div class="container mx-auto p-4">
                        <div class="flex items-center justify-center">
                            <div class="flex items-center space-x-4">
                                <div>
                                    <button :disabled="isButtonDisabled" @click="handleCheckIn"
                                        :class="{ 'bg-gray-400 cursor-not-allowed': isButtonDisabled, 'bg-blue-500 cursor-pointer': !isButtonDisabled }"
                                        class="px-4 py-2 rounded-md text-white">
                                        {{ isButtonDisabled ? '今日已签到' : '签到' }}
                                    </button>
                                    <transition name="slide-fade">
                                        <div v-if="showNotification" key="notification"
                                            class="mt-2 p-2 bg-green-100 rounded-md">
                                            <p>恭喜您，获得 {{ getCoins }} 枚硬币！</p>
                                        </div>
                                    </transition>
                                </div>

                                <div class="relative">
                                    <img :src="user.avatar" alt="Avatar" class="w-24 h-24 rounded-full object-cover" />
                                    <span v-if="user.level"
                                        class="absolute bottom-0 right-0 flex items-center justify-center w-8 h-8 text-base font-bold border-2 rounded-full shadow-lg"
                                        :class="getUserLevelStyle(user.level)">
                                        {{ getUserLevelText(user.level) }}
                                    </span>
                                </div>

                                <div>
                                    <button @click="isModalOpen = true"
                                        class="bg-blue-500 text-white px-4 py-2 rounded">选择头像</button>
                                </div>
                            </div>
                            <div class="relative">

                                <div v-if="isModalOpen" class="fixed inset-0 z-10 overflow-y-auto">
                                    <div
                                        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                                            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                                        </div>

                                        <!-- This element is to trick the browser into centering the modal contents. -->
                                        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
                                            aria-hidden="true">&#8203;</span>

                                        <div class="inline-block align-bottom bg-white dark:bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                                            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-200">
                                                <div class="sm:flex sm:items-start">
                                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                        <h3 class="text-lg leading-6 font-medium text-gray-900"
                                                            id="modal-headline">
                                                            选择头像
                                                        </h3>

                                                        <div class="container mx-auto p-4">
                                                            <div
                                                                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                                                <button v-for="avatar in avatars" :key="avatar"
                                                                    class="flex items-center justify-center w-full h-12 rounded-lg cursor-pointer bg-gray-200 hover:bg-gray-300"
                                                                    @click="selectAvatar(avatar)">
                                                                    <img :src="avatar"
                                                                        class="w-full h-full object-cover" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col items-center space-y-6">
                                                <h4 class="text-2xl font-semibold text-center mb-1">自定义头像</h4>

                                                <div
                                                    class="container mx-auto p-4 w-full flex flex-col items-center space-y-4">
                                                    <div v-if="imageSrc">
                                                        <img id="image" :src="imageSrc"
                                                            class="w-full h-full object-cover" />
                                                    </div>
                                                    <label class="block" v-show="!imageSrc">
                                                        <input type="file" @change="onFileChange" accept="image/*"
                                                            class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100">
                                                    </label>
                                                    <div class="w-full max-w-md mt-4" v-show="!imageSrc">
                                                        <label for="avatarUrl"
                                                            class="block text-gray-700 font-medium mb-1">头像URL</label>
                                                        <input id="avatarUrl" type="text" v-model="user.avatar"
                                                            class="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow shadow-sm text-sm">
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="bg-gray-50 dark:bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                <button @click="uploadCroppedImage" v-show="imageSrc"
                                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                                                    上传头像
                                                </button>
                                                <button @click="closeModal"
                                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                                    关闭
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 可以在这里添加其他信息，如用户名、职位等 -->
                <div>
                    <p class="text-red-500 font-bold">您的账号有效期至：{{ formatTime(user.expirationDate) }}</p>
                    <button @click="showPopup = true"
                        class="text-black px-4 py-2 rounded underline cursor-pointer hover:text-blue-500">续费</button>
                </div>
                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">硬币数</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <span class="text-lg dark:text-gray-900">{{ user.coins }}</span>
                        </div>
                    </div>
                </div>

                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">职位</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <input type="text" id="position" v-model="user.position" placeholder="请输入您的职位"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-900"
                                required />
                        </div>
                    </div>
                </div>

                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">城市</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <input type="text" id="city" v-model="user.city" placeholder="请输入您的城市"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-900"
                                required />
                        </div>
                    </div>
                </div>

                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">工作年限</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <label for="seniority"
                                class="block text-sm font-medium text-gray-700 dark:text-gray-300"></label>
                            <input type="text" id="seniority" v-model="user.seniority" placeholder="请输入您的工作年限"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-900"
                                required />
                        </div>
                    </div>
                </div>

                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">昵称</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <label for="name"
                                class="block text-sm font-medium text-gray-700 dark:text-gray-300"></label>
                            <input type="text" id="name" v-model="user.name" placeholder="请输入您的昵称"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-900"
                                required />
                        </div>
                    </div>
                </div>

                <div class="container mx-auto p-4">
                    <div class="flex items-center">
                        <!-- 添加描述标签 -->
                        <span class="mr-2 text-1xl font-medium text-gray-700 dark:text-gray-900">GitHub账号</span>

                        <!-- 输入框 -->
                        <div class="flex-1">
                            <label for="github"
                                class="block text-sm font-medium text-gray-700 dark:text-gray-300"></label>
                            <input type="text" id="github" v-model="user.github" placeholder="旗舰版才需要填写"
                                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-200 dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-900" />
                        </div>
                    </div>
                </div>

                <div v-if="errorMessage" class="error-message p-2 my-4 bg-red-100 rounded-lg text-red-800">
                    <p>{{ errorMessage }}</p>
                    <button @click="clearErrorMessage"
                        class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">
                        关闭
                    </button>
                </div>

                <!-- 新增成功信息提示 -->
                <div v-if="successMessage" class="success-message p-2 my-4 bg-green-100 rounded-lg text-green-800">
                    <p>{{ successMessage }}</p>
                    <button @click="clearSuccessMessage"
                        class="ml-2 px-2 py-1 rounded-md text-green-600 hover:text-green-700">
                        关闭
                    </button>
                </div>
                <button @click="saveUserInfo()"
                    class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">保存</button>


                <button @click="showModal = true"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    修改密码
                </button>

                <!-- 简易模态对话框 -->
                <div v-if="showModal"
                    class="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
                    <div class="bg-white rounded-lg shadow-lg p-6 w-1/3">
                        <h2 class="text-2xl font-bold mb-4">修改密码</h2>
                        <form @submit.prevent="resetPassword">
                            <div class="mb-4">
                                <label for="oldPassword" class="block text-sm font-medium text-gray-700">旧密码</label>
                                <input v-model="oldPassword" type="password" id="oldPassword"
                                    class="mt-1 p-2 w-full border rounded-md">
                            </div>
                            <div class="mb-4">
                                <label for="newPassword" class="block text-sm font-medium text-gray-700">新密码</label>
                                <input v-model="newPassword" type="password" id="newPassword"
                                    class="mt-1 p-2 w-full border rounded-md">
                            </div>
                            <div class="mb-4">
                                <label for="confirmPassword"
                                    class="block text-sm font-medium text-gray-700">确认新密码</label>
                                <input v-model="confirmPassword" type="password" id="confirmPassword"
                                    class="mt-1 p-2 w-full border rounded-md">
                            </div>

                            <div v-if="updatePwdErrorMessage"
                                class="error-message p-2 my-4 bg-red-100 rounded-lg text-red-800">
                                <p>{{ updatePwdErrorMessage }}</p>
                                <button @click="updatePwdErrorMessage = ''"
                                    class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">
                                    关闭
                                </button>
                            </div>

                            <div class="flex justify-end">
                                <button type="submit"
                                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">保存</button>
                                <button @click="showModal = false"
                                    class="ml-4 text-gray-500 hover:text-gray-700">取消</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container mx-auto p-4" v-if="active === 1">
        <div v-for="post in postList" :key="post.id"
            class="bg-white border-2 shadow-md rounded-md p-6 cursor-pointer m-3 dark:bg-gray-200"
            @click="goToPostDetail(post)">
            <div class="flex items-center">
                <span class="font-bold mr-auto">{{ post.creatorName }}</span>
                <div class="flex">
                    <div class="text-1xl font-medium w-full">
                        {{ post.title }}
                    </div>
                </div>
                <span class="text-gray-600 text-sm ml-auto">{{ $formatDate(post.createTime) }}</span>
            </div>
        </div>

        <!-- Pagination -->
        <div class="mt-4">
            <nav class="flex items-center justify-between">
                <button @click="previousPage" :disabled="currentPage === 1"
                    class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50">
                    Previous
                </button>
                <button @click="nextPage" :disabled="currentPage === totalPages"
                    class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50">
                    Next
                </button>
            </nav>
        </div>
    </div>

    <transition name="popup-fade">
        <div v-if="showPopup"
            class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                <div v-if="user.level === 1" class="text-center mb-4">
                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_com.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_com.JPG" alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">支付请备注邮箱</p>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_com.JPG" style="width:50%"
                                    alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_com.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">支付请备注邮箱</p>
                    </div>
                </div>
                <div v-else class="text-center mb-4">

                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_ulti.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_ulti.JPG" alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">支付请备注邮箱</p>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_ulti.JPG"
                                    style="width:50%" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_ulti.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">支付请备注邮箱</p>
                    </div>

                </div>
                <button @click="showPopup = false"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
            </div>
        </div>
    </transition>
</template>

<script>
import Cookies from 'js-cookie';
import md5 from 'js-md5'
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { imageSource, userLevel } from '@/common/common';
import { userCheckIn, saveUser, updatePassword, getCheckIn, getUserDetail } from '@/openapi/user';
import { getCollectList } from '@/openapi/post';
import { uploadImage } from '@/openapi/image';


export default {
    data() {
        return {
            loading: false,
            user: {},
            isModalOpen: false,
            avatars: [],
            selectedAvatar: '',
            errorMessage: '',
            updatePwdErrorMessage: '',
            successMessage: '', // 成功信息
            showModal: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            showNotification: false,
            getCoins: null,
            isButtonDisabled: false, // 添加一个状态来控制按钮是否禁用
            active: 0,
            currentPage: 1,
            itemsPerPage: 10,
            postList: [],
            imageSrc: null,
            cropper: null,
            inImageUploading: false,
            showPopup: false
        };
    },
    methods: {
        logout() {
            // 实现退出登录的逻辑，例如清除用户信息和重定向到登录页面
            this.$router.push('/login');
        },
        selectAvatar(avatar) {
            this.selectedAvatar = avatar;
            // 在这里可以添加更多处理逻辑，比如保存选择的头像到用户资料
            this.user.avatar = avatar;
        },
        async saveUserInfo() {
            try {
                const response = await saveUser(this.user);
                if (response.data.code === 200) {
                    localStorage.setItem('user', JSON.stringify(this.user));
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.successMessage = "已保存"
                    this.errorMessage = ""
                } else {
                    this.errorMessage = response.data.message
                }
            } catch (error) {
                console.error('Error saving user information:', error);
            }
        },
        goToPostDetail(post) {
            this.$router.push({
                path: "postDetail", query: {
                    id: post.postId
                }
            })
        },
        clearErrorMessage() {
            this.errorMessage = '';
        },
        clearSuccessMessage() {
            this.successMessage = '';
        },
        async resetPassword() {
            if (this.newPassword !== this.confirmPassword) {
                alert("新密码和确认密码不一致！");
                return;
            }
            // 假设的API调用示例
            const resp = await updatePassword({
                oldPwd: md5(this.oldPassword),
                pwd: md5(this.newPassword)
            });
            if (resp.data.code !== 200) {
                this.updatePwdErrorMessage = resp.data.message
                return
            }
            // 成功后的处理，关闭模态框，清除表单等
            alert("修改重置成功，请重新登录！"); // 实际应用中应使用更友好的反馈方式
            this.showModal = false;
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';

            Cookies.remove('token');
            localStorage.removeItem('user');
            this.$router.push('/'); // 如果未登录，跳转到登录页面
        },
        async handleCheckIn() {
            const resp = await userCheckIn();
            if (resp.data.code !== 200) {
                alert(resp.data.message)
                return
            }
            this.getCoins = resp.data.data
            this.showNotification = true;
            this.user.coins += this.getCoins
            localStorage.setItem('user', JSON.stringify(this.user))
            this.checkIn();
        },
        async checkIn() {
            const resp = await getCheckIn();
            if (resp.data.code !== 200) {
                alert(resp.data.message)
                return
            }
            this.isButtonDisabled = resp.data.data
        },
        loadPosts() {
            // 发送请求获取分页数据
            this.fetchPosts(this.currentPage, this.pageSize);
        },
        async fetchPosts(page, size) {
            try {
                const response = await getCollectList({
                    page: this.currentPage,
                    size: this.itemsPerPage
                });
                this.postList = response.data.data.list;
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
        formatTime(timestamp) {
            // 实现日期格式化的逻辑...
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.loadPosts()
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.loadPosts()
            }
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageSrc = e.target.result;
                    this.$nextTick(() => {
                        const image = document.getElementById('image');
                        this.cropper = new Cropper(image, {
                            aspectRatio: 1,
                            viewMode: 2,
                            preview: '.preview',
                        });
                    });
                };
                reader.readAsDataURL(file);
            }
        },
        async uploadCroppedImage() {
            if (this.cropper) {
                const canvas = this.cropper.getCroppedCanvas();
                canvas.toBlob(async (blob) => {
                    const file = new File([blob], 'avatar.jpg', { type: 'image/jpeg' });
                    const imageUrl = await this.uploadFile(file);
                    if (imageUrl) {
                        this.user.avatar = imageUrl;
                    }
                });
            }
            this.closeModal();
        },
        async uploadFile(file) {
            this.inImageUploading = true;

            try {
                const response = await uploadImage(file, imageSource.AVATAR);
                if (response.status != 200) {
                    alert("图片上传失败");
                    return null;
                }
                return response.data.data;
            } catch (error) {
                console.error('上传失败:', error);
                return null;
            } finally {
                this.inImageUploading = false;
            }
        },
        closeModal() {
            this.isModalOpen = false;
            if (!this.cropper) return
            this.cropper.destroy();
            this.imageSrc = null;
        },

        getUserLevelStyle(level) {
            if (level === userLevel.ADMIN || level == userLevel.ULTIMATE) {
                return 'text-[#42698c] border-[#42698c] bg-[#edefec]';
            }
            return 'text-white border-[#f2f3f5] bg-[#409eff]';
        },
        getUserLevelText(level) {
            if (level === userLevel.ADMIN) {
                return "管";
            }
            if (level === userLevel.ULTIMATE) {
                return "旗"
            }
            return "社";
        }
    },
    created() {
        var active = this.$route.query.active
        if (active === undefined) {
            // 避免第一次登陆时，默认显示个人资料页
            active = 0
        }
        // 这里需要转换为int
        this.active = parseInt(active)

        if (this.active === 0) {
            getUserDetail().then(resp => this.user = resp.data.data)
            var list = ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', 'jihai', '23']
            for (let i = 0; i < list.length; i++) {
                this.avatars.push('/static/avatars/' + list[i] + '.png')
            }
            this.checkIn()
        }

        if (this.active === 1) {
            this.loadPosts()
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.postList.length / this.itemsPerPage);
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.postList.slice(start, end);
        },
    }

};
</script>


<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

tr:hover {
    background-color: #f5f5f5;
    /* 更深的颜色 */
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    flex-direction: column;
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}
</style>