<template>
    <div class="task-list">
        <div class="scrolling-notification mb-5">
            <div class="notification-content text-red-500 ">
                领取任务后，开发结束再提交审核，审核通过后，任务状态会变成已完成并奖励硬币。 开发贡献500硬币 & 面试（5折付费）通过后可获取审批review权限.
            </div>
        </div>

        <!-- 移动端 表格 -->
        <table class="w-full table-auto sm:hidden">
            <thead>
                <tr class="border-b border-gray-300">
                    <th class="px-4 py-2">任务名称</th>
                    <th class="px-4 py-2 text-sm">创建时间</th>
                    <th class="px-4 py-2 text-sm">DeadLine</th>
                    <th class="px-4 py-2">开发</th>
                    <th class="px-4 py-2 text-sm">硬币数</th>
                    <th class="px-4 py-2">备注</th>
                    <th class="px-4 py-2">状态</th>
                    <th class="px-4 py-2 text-sm">Review</th>
                    <th class="px-4 py-2">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(task, index) in tasks" :key="index" class="border-b border-gray-300">
                    <td class="px-4 py-2">{{ task.content }}</td>
                    <td class="px-4 py-2 text-xs">{{ this.time(task.createTime) }}</td>
                    <td class="px-4 py-2 text-xs">{{ this.time(task.ddl) }}</td>
                    <td class="px-4 py-2 text-sm">{{ task.userName }}</td>
                    <td class="px-4 py-2">{{ task.coins }}</td>
                    <td class="px-4 py-2">{{ task.remark }}</td>
                    <td class="px-4 py-2 text-sm">{{ task.statusDesc }}</td>
                    <td class="px-4 py-2">{{ task.reviewName }}</td>
                    <td class="px-4 py-2 text-sm">
                        <button v-if="task.status == 0" @click="showReceiveModel(task)"
                            class="bg-green-500 text-white px-4 py-2 rounded">
                            领取任务
                        </button>
                        <button v-if="task.status == 1 && task.userId == user.id" @click="showSubmitModel(task)"
                            class="bg-blue-500 text-white px-4 py-2 rounded">
                            提交审核
                        </button>
                        <button v-if="canReview(task)" @click="showReviewModal(task)"
                            class="bg-blue-500 text-white px-4 py-2 rounded">
                            审核
                        </button>

                        <button v-if="task.status === 3">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- PC端 表格 -->
        <table class="w-full table-auto hidden sm:block">
            <thead>
                <tr class="border-b border-gray-300">
                    <th class="px-4 py-2">任务名称</th>
                    <th class="px-4 py-2 text-sm">创建时间</th>
                    <th class="px-4 py-2 text-sm">DeadLine</th>
                    <th class="px-4 py-2">开发</th>
                    <th class="px-4 py-2 text-sm">硬币数</th>
                    <th class="px-4 py-2">备注</th>
                    <th class="px-4 py-2">状态</th>
                    <th class="px-4 py-2 text-sm">Review</th>
                    <th class="px-4 py-2">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(task, index) in tasks" :key="index" class="border-b border-gray-300 whitespace-nowrap">
                    <td class="px-4 py-2 whitespace-normal">{{ task.content }}</td>
                    <td class="px-4 py-2 text-xs">{{ this.time(task.createTime) }}</td>
                    <td class="px-4 py-2 text-xs">{{ this.time(task.ddl) }}</td>
                    <td class="px-4 py-2 text-sm">{{ task.userName }}</td>
                    <td class="px-4 py-2">{{ task.coins }}</td>
                    <td class="px-4 py-2 whitespace-normal">{{ task.remark }}</td>
                    <td class="px-4 py-2 text-sm">{{ task.statusDesc }}</td>
                    <td class="px-4 py-2">{{ task.reviewName }}</td>
                    <td class="px-4 py-2 text-sm">
                        <button v-if="task.status == 0" @click="showReceiveModel(task)"
                            class="bg-green-500 text-white px-4 py-2 rounded">
                            领取任务
                        </button>
                        <button v-if="task.status == 1 && task.userId == user.id" @click="showSubmitModel(task)"
                            class="bg-blue-500 text-white px-4 py-2 rounded">
                            提交审核
                        </button>
                        <button v-if="canReview(task)" @click="showReviewModal(task)"
                            class="bg-blue-500 text-white px-4 py-2 rounded">
                            审核
                        </button>
                        <button v-if="task.status === 3">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination flex justify-center mt-4">
            <button @click="prevPage" :disabled="currentPage === 1"
                class="px-4 py-2 mr-2 bg-gray-300 rounded">上一页</button>
            <button @click="nextPage" :disabled="itemsPerPage > tasks.length"
                class="px-4 py-2 bg-gray-300 rounded">下一页</button>
        </div>
        <!-- 领取任务模态框 -->
        <transition name="receiveTask">
            <div v-if="receiveModalVisible"
                class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div class="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                    <div class="flex justify-between items-center border-b pb-4">
                        <h4 class="text-lg font-medium">领取任务</h4>
                        <button @click="closeReceiveModal" class="text-gray-400 hover:text-gray-600">关闭</button>
                    </div>

                    <div class="mt-4">
                        <label for="completeDays" class="block text-base text-gray-700">请输入开发天数：</label>
                        <input v-model="completeDays" type="number" id="completeDays" min="1"
                            class="mt-2 w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="开发任务预计需要的天数">
                    </div>

                    <div class="mt-6 text-right">
                        <button @click="receiveTask"
                            class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                            确认领取
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 提交审核模态框 -->
        <transition name="submitTask">
            <div v-if="submitModalVisible"
                class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl p-6">
                    <div class="flex justify-between items-center border-b pb-3">
                        <h4 class="text-lg font-medium">提交审核任务</h4>
                        <button @click="closeSubmitModal" class="text-gray-400 hover:text-gray-600">
                            关闭
                        </button>
                    </div>
                    <div class="mt-4">
                        <p class="text-sm text-gray-700">请填写 PR 链接（可以添加多个）：</p>
                        <div class="space-y-3 mt-3">
                            <div v-for="(link, index) in prLinks" :key="index" class="flex items-center space-x-2">
                                <input type="url" v-model="prLinks[index]" placeholder="请输入 PR 链接"
                                    class="w-full px-3 py-2 border border-gray-300 rounded focus:ring-1 focus:ring-blue-400" />
                                <button @click="removePrLink(index)" class="text-red-500 hover:text-red-600">
                                    删除
                                </button>
                            </div>
                            <button @click="addPrLink" class="text-blue-500 hover:text-blue-600">
                                添加 PR 链接
                            </button>
                        </div>
                    </div>
                    <div class="mt-6 text-right">
                        <button @click="submitPrLinks"
                            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                            提交
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 审核模态框 -->
        <transition name="modal">
            <div v-if="reviewModalVisible"
                class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl p-6">
                    <div class="flex justify-between items-center border-b pb-4">
                        <h4 class="text-xl font-medium">审核任务</h4>
                        <button @click="closeReviewModal" class="text-gray-400 hover:text-gray-600">
                            关闭
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-base text-gray-700 mb-3">请选择审核结果：</p>
                        <div class="form-group">
                            <label>
                                <input type="radio" name="reviewResult" value="pass" v-model="reviewResult"
                                    class="mr-2">
                                通过
                            </label>
                            <label>
                                <input type="radio" name="reviewResult" value="reject" v-model="reviewResult"
                                    class="mr-2">
                                拒绝
                            </label>
                        </div>
                        <textarea v-model="reviewRemarks" placeholder="请输入备注" class="form-control"></textarea>
                    </div>
                    <!-- PR 链接展示区域 -->
                    <div v-if="this.reviewTask.remark" class="mt-6 text-left">
                        <p class="text-base text-gray-700">相关 PR 链接：</p>
                        <ul class="list-disc ml-6 mt-2 space-y-1">
                            <li v-for="(link, index) in this.reviewTask.remark.split(',').map(l => l.trim())"
                                :key="index">
                                <a :href="link" target="_blank" class="text-blue-500 hover:text-blue-600">
                                    {{ link }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-8 text-right">
                        <button @click="submitReview"
                            class="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                            提交
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { getList, canVerify, updateTask } from '@/openapi/task';
import { formatTime2Day, taskStatus } from '@/common/common';

export default {
    data() {
        return {
            tasks: [],
            currentUser: '王五',
            currentPage: 1,
            itemsPerPage: 10,
            receiveModalVisible: false,
            reviewModalVisible: false,
            submitModalVisible: false,
            reviewTask: null,
            reviewResult: '',
            reviewRemarks: '',
            verify: false,
            prLinks: [''],
            completeDays: 5, // 默认5天
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.tasks.length / this.itemsPerPage);
        },
        paginatedTasks() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.tasks.slice(start, end);
        }
    },
    methods: {
        time(date) {
            if (!date) {
                return "-";
            }
            return formatTime2Day(date);
        },
        handleClaim(task) {
            if (task.status === taskStatus.TODO) {
                task.status = taskStatus.DOING;
                task.developer = this.currentUser;
            }
        },
        showReceiveModel(task) {
            this.reviewTask = task;
            this.receiveModalVisible = true;
        },
        showReviewModal(task) {
            this.reviewTask = task;
            this.reviewModalVisible = true;
        },
        showSubmitModel(task) {
            this.reviewTask = task;
            this.submitModalVisible = true;
        },
        closeReceiveModal() {
            this.completeDays = 5;
            this.reviewTask = null;
            this.receiveModalVisible = false;
        },
        closeSubmitModal() {
            this.prLinks = [''];
            this.reviewTask = null;
            this.submitModalVisible = false;
        },
        closeReviewModal() {
            this.reviewModalVisible = false;
            this.reviewResult = '';
            this.reviewRemarks = '';
        },
        addPrLink() {
            this.prLinks.push('');
        },
        removePrLink(index) {
            this.prLinks.splice(index, 1);
        },
        receiveTask() {
            updateTask({
                id: this.reviewTask.id,
                userId: this.user.id,
                status: taskStatus.DOING,
                remark: this.reviewRemarks,
                completeDays: this.completeDays
            }).then(resp => {
                if (resp.data.code !== 200) {
                    alert(resp.data.message);
                    return;
                }
                this.fetchTaskList();
            });
            this.closeReceiveModal();
        },
        submitPrLinks() {
            const remark = this.prLinks.join(',');
            updateTask({
                id: this.reviewTask.id,
                userId: this.reviewTask.userId,
                status: taskStatus.SUBMIT,
                remark
            }).then(resp => {
                if (resp.data.code !== 200) {
                    alert(resp.data.message);
                    return;
                }
                this.fetchTaskList();
            });
            this.closeSubmitModal();
        },
        submitReview() {
            if (this.reviewResult === 'pass') {
                updateTask({
                    id: this.reviewTask.id,
                    userId: this.reviewTask.userId,
                    status: taskStatus.DONE,
                    reviewId: this.user.id,
                    remark: this.reviewRemarks
                }).then(resp => this.fetchTaskList())
            } else if (this.reviewResult === 'reject') {
                updateTask({
                    id: this.reviewTask.id,
                    userId: this.reviewTask.userId,
                    status: taskStatus.DOING,
                    reviewId: this.user.id,
                    remark: this.reviewRemarks,
                    reject: true
                }).then(resp => this.fetchTaskList())
            }
            this.closeReviewModal();
        },
        updateTask(id, userId, status, reviewId, remark) {
            updateTask({
                id: id, userId: userId, status: status, reviewId: reviewId, remark: remark
            }).then(resp => {
                if (resp.data.code != 200) {
                    alert(resp.data.message)
                    return
                }
                this.fetchTaskList()
            })

        },
        handlePass(task, remarks) {
            task.status = 2;
            task.remarks = remarks;
            task.reviewedBy = this.currentUser;
        },
        handleReject(task, remarks) {
            task.status = 3;
            task.remarks = remarks;
            task.reviewedBy = this.currentUser;
        },
        canReview(task) {
            return this.verify && task.status === taskStatus.SUBMIT;
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
            this.fetchTaskList();
        },
        nextPage() {
            this.currentPage++;
            this.fetchTaskList();
        },
        fetchTaskList() {
            getList({
                page: this.currentPage,
                size: this.itemsPerPage
            }).then((resp) => {
                this.tasks = resp.data.data.list;
            });
        },
    },
    created() {
        this.fetchTaskList();
        canVerify().then(resp => this.verify = resp.data.data);
        this.user = JSON.parse(localStorage.getItem("user"))
    }
};
</script>

<style scoped>
.task-list {
    @apply max-w-6xl mx-auto my-3;
}

table {
    @apply w-full table-auto;
}

th,
td {
    @apply border border-gray-300 px-4 py-2;
}

th {
    @apply bg-gray-200;
}

.pagination button {
    @apply px-4 py-2 bg-gray-300 rounded;
}

.pagination button:disabled {
    @apply bg-gray-400 cursor-not-allowed;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-dialog {
    width: 400px;
    margin: 30px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
}

.modal-header h4 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-footer {
    text-align: right;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity .3s ease;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}

.form-control {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.close-modal {
    float: right;
    cursor: pointer;
}
</style>