<template>
    <div class="flex h-screen overflow-y-auto dark:bg-gray-300" @scroll="onScroll">
        <button @click="isOnlineUsersOpen = !isOnlineUsersOpen"
            class="fixed top-4 left-4 z-20 p-1 text-gray-600 bg-white rounded-full shadow-md focus:outline-none hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
        </button>

        <div class="flex-shrink-0 w-1/5 bg-gray-100 overflow-y-auto dark:bg-slate-300"
            v-bind:class="{ hidden: !isOnlineUsersOpen }">
            <!-- 左侧联系人列表 -->
            <div class="px-4 py-2 space-y-4">
                <transition name="fade">
                    <div class="px-4 py-2 space-y-4 whitespace-nowrap">
                        <h3 class="text-lg font-medium">在线用户({{ onlineUsers.length }}人)</h3>
                        <div v-for="user in onlineUsers" :key="user.id"
                            class="flex items-center px-4 py-2 cursor-pointer">
                            <img :src="user.avatar" alt="Avatar" class="w-8 h-8 rounded-full object-cover mr-3">
                            <div class="flex flex-col justify-between">
                                <!-- 使用内联样式控制文本溢出 -->
                                <span class="text-gray-900 block truncate max-w-full" :title="user.name">
                                    {{ user.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="flex-1 md:w-3/5 mt-2">
            <div class="scrolling-notification">
                <div class="notification-content text-red-500 dark:text-red-800">
                    {{ notification }}
                </div>
            </div>
            <!-- 聊天窗口 -->
            <div class="relative h-full">
                <div id="message-inputer" class="w-full">
                    <form @submit="sendMessage"
                        class="flex flex-col p-4 bg-white border border-gray-300 rounded-lg shadow-sm dark:bg-slate-400 dark:border-slate-500">
                        <div class="flex items-center">
                            <textarea v-model="inputMessage" ref="messageInput"
                                class="flex-grow bg-gray-200 border border-gray-300 text-gray-900 dark:text-stone-800 rounded-lg py-2 px-3 mx-2 focus:outline-none focus:ring-2 focus:ring-blue-400 resize-y overflow-auto dark:bg-gray-300 dark:border-gray-400"
                                placeholder="输入消息..." @input="handleInput" @keydown="handleKeyDown" rows="2"></textarea>
                            <!-- 在输入框下方添加提及建议列表 -->
                            <ul v-if="mentionSuggestions && mentionSuggestions.length > 0" class="mention-suggestions">
                                <li v-for="suggestion in mentionSuggestions" :key="suggestion.id"
                                    @click="selectMention(suggestion)">
                                    {{ suggestion.name }}
                                </li>
                            </ul>

                            <!-- 表情 -->
                            <div class="relative inline-block">
                                <button @click="showEmojiPicker = !showEmojiPicker" type="button"
                                    class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-200 focus:outline-none">
                                    <img :src="emojiSelector" class="h-6 w-6 text-gray-600" />
                                </button>
                                <div v-show="showEmojiPicker"
                                    class="absolute left-0 mt-2 z-10 bg-white rounded-lg shadow-xl p-4 w-80 overflow-auto">
                                    <div class="flex justify-end">
                                        <button @click="showEmojiPicker = false" type="button"
                                            class="text-gray-400 hover:text-gray-900 focus:outline-none">
                                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="flex flex-wrap mt-2">
                                        <span v-for="emoji in emojis" :key="emoji" @click="addEmoji(emoji)">
                                            <img :src="'static/emoji/' + emoji + '.webp'"
                                                class="w-10 h-10 object-contain mr-2 cursor-pointer" alt="emoji" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- 图片上传 -->
                            <label for="image-upload"
                                class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-200 cursor-pointer">
                                <img :src="imageUploder" class="h-6 w-6 text-gray-600" />
                            </label>
                            <input id="image-upload" type="file" accept="image/*" @change="onImageSelect"
                                class="hidden">

                            <button type="submit" class="bg-blue-600 text-white hover:bg-blue-700 rounded-lg py-2 px-4">
                                发送
                            </button>
                        </div>

                        <!-- 引用消息显示 -->
                        <div v-if="quotedMessage"
                            class="flex items-center mt-2 bg-gray-100 border-l-4 border-blue-500 rounded-lg p-2 max-w-full overflow-hidden">
                            <p @click="scrollToItem(quotedMessage?.id)"
                                class="text-gray-900 text-left flex-grow break-words max-w-full overflow-hidden text-ellipsis">
                                <span class="font-medium">{{ quotedMessage?.userName }}: </span>
                                <span v-html="replaceEmojiWithImages(quotedMessage?.content)"></span>
                            </p>
                            <button @click="clearQuotedMessage"
                                class="text-gray-500 hover:text-gray-700 focus:outline-none flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </form>

                    <div v-if="isUploading"
                        class="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
                        <div class="bg-white rounded-lg shadow-xl p-6 text-center">
                            <div class="flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <i class="fas fa-spinner fa-pulse text-blue-600"></i>
                            </div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900 mt-3">上传中...</h3>
                        </div>
                    </div>
                </div>

                <div class="p-5">
                    <!-- 悬浮框：跳转到上次未读位置 -->
                    <transition name="fade">
                        <div v-if="hasUnread" class="absolute top-50 right-0 z-50 flex items-center p-3 max-w-80">
                            <button @click="scrollToLastUnread"
                                class="bg-white hover:bg-gray-100 text-green-500 rounded-full px-4 py-2 shadow-sm transition flex items-center">
                                <img :src="scrollDownIcon" class="mr-2 w-4 h-4 inline-flex" />
                                <span class="text-sm font-semibold">{{ unReadCount }} 条未读消息</span>
                            </button>
                        </div>
                    </transition>

                    <!-- 聊天记录 -->
                    <div class="overflow-y-auto chat-container">
                        <div v-for="(message, index) in messages" :key="message.id"
                            class="relative flex items-center space-x-3 mb-4" :class="getJustify(message)">
                            <div :id="message.id" v-if="message.userId === user.id" ref="chatList"
                                class="relative flex items-center space-x-3 mb-4">
                                <div class="rounded-lg p-3 shadow-md flex flex-col items-end space-y-2 bg-green-200"
                                    @mouseover="onMouseOver(index, true)">
                                    <div class=" flex flex-col items-end w-full">
                                        <div class="max-w-[content] overflow-hidden whitespace-pre-wrap text-left">
                                            <p class="text-gray-900 text-xl font-bold break-all"
                                                v-html="replaceEmojiWithImages(message.content)"></p>
                                        </div>

                                        <p class="text-gray-600 text-xs italic text-right mt-1">
                                            {{ formatTime(message.createTime) }}
                                        </p>
                                    </div>

                                    <!-- 引用 -->
                                    <div v-if="message.mentionedContent"
                                        @click="scrollToItem(message.mentionedMessageId)"
                                        class="mt-2 bg-gray-100 border-l-4 border-blue-500 rounded-lg p-2 w-full text-left">
                                        <p class="text-base break-all"
                                            v-html="replaceEmojiWithImages(message.mentionedContent)"></p>
                                        <p class="text-gray-600 text-xs italic">
                                            {{ message.mentionedUserName }}
                                        </p>
                                    </div>

                                    <div class="flex justify-end w-full space-x-2 mt-2" v-show="message.selfMsg">
                                        <button
                                            class="text-blue-600 underline underline-offset-2 text-base transition duration-200 hover:text-blue-400"
                                            @click="replyMessage(message)">
                                            回复
                                        </button>
                                        <button @click="retractMessage(index)"
                                            class="text-red-600 underline underline-offset-2 text-base transition duration-200 hover:text-red-400">
                                            撤回
                                        </button>
                                    </div>
                                </div>

                                <div class="flex flex-col items-center">
                                    <!-- 新增容器并使用Flex布局 -->
                                    <div class="relative w-12 h-12 rounded-full bg-gray-200 flex-shrink-0">
                                        <img :src="message.avatar" alt="Avatar"
                                            class="w-full h-full rounded-full object-cover" />
                                    </div>
                                    <span :class="message.userId === 701 ? 'text-pink-500' : 'text-gray-700'"
                                        class="font-medium text-center text-sm mt-1">
                                        {{ message.userName }}
                                    </span>
                                </div>
                            </div>

                            <div :id="message.id" v-if="message.userId !== user.id" ref="chatList"
                                class="relative flex items-center space-x-3 mb-4">

                                <div class="flex flex-col items-center">
                                    <div class="relative w-12 h-12 rounded-full bg-gray-200 flex-shrink-0">
                                        <img :src="message.avatar" alt="Avatar"
                                            class="w-full h-full rounded-full object-cover" />
                                    </div>
                                    <span :class="message.userId === 701 ? 'text-pink-500' : 'text-gray-700'"
                                        class="font-medium text-center text-sm mt-1">
                                        {{ message.userName }}
                                    </span>
                                </div>

                                <!-- 消息内容与引用消息 -->
                                <div class="rounded-lg p-3 border-2 shadow-md flex flex-col items-start space-y-2 w-full bg-white dark:bg-gray-100"
                                    @mouseover="onMouseOver(index, false)">
                                    <!-- 消息内容 -->
                                    <div class="flex flex-col w-full">
                                        <div class="max-w-full overflow-hidden whitespace-pre-wrap text-left">
                                            <p class="text-gray-900 text-xl font-bold break-all"
                                                v-html="replaceEmojiWithImages(message.content)"></p>
                                        </div>

                                        <p class="text-gray-600 text-xs italic text-right mt-1">
                                            {{ formatTime(message.createTime) }}
                                        </p>
                                    </div>

                                    <!-- 引用消息 -->
                                    <div v-if="message.mentionedContent"
                                        @click="scrollToItem(message.mentionedMessageId)"
                                        class="mt-2 bg-gray-100 border-l-4 border-blue-500 rounded-lg p-2 w-full text-left">
                                        <p class="text-base break-all"
                                            v-html="replaceEmojiWithImages(message.mentionedContent)"></p>
                                        <p class="text-gray-600 text-xs italic">
                                            {{ message.mentionedUserName }}
                                        </p>
                                    </div>

                                    <!-- 回复按钮 -->
                                    <div class="flex justify-end w-full space-x-2 mt-2" v-show="message.otherMsg">
                                        <button
                                            class="text-blue-600 underline underline-offset-2 text-base transition duration-200 hover:text-blue-400"
                                            @click="replyMessage(message)">
                                            回复
                                        </button>
                                    </div>
                                </div>

                                <!-- 上次阅读标记 -->
                                <div v-if="lastReadIndex == message.id"
                                    class="relative flex items-center space-x-3 mb-4">
                                    <div class="left-arrow"></div>
                                    <div class="justify-end">
                                        <div
                                            class="max-w-full overflow-hidden whitespace-pre-wrap text-2xl font-bold blink">
                                            上次读到这里
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <button @click="loadMoreMessages">
                    <div class="flex justify-center items-center w-full mt-4 cursor-pointer">
                        <p class="text-gray-600 dark:text-gray-400">下滑或点击加载更多</p>
                    </div>
                </button>
            </div>
        </div>

        <div class="flex-shrink-0 w-1/5 bg-gray-100 overflow-y-auto dark:bg-slate-300"
            v-bind:class="{ hidden: !isOnlineUsersOpen }">
            <div class="px-4 py-2 space-y-4">
                <transition name="fade">
                    <div>
                        <h3 class="text-lg font-medium whitespace-nowrap">硬币排行榜</h3>
                        <h4>我的排名：{{ myRankPostion }}</h4>
                        <div v-if="this.rankUserList.length > 0">
                            <div v-for="(user, index) in rankUserList" :key="user.id || index">
                                <div class="flex justify-between items-center mt-5">
                                    <img :src="user.avatar" alt="Avatar" class="w-10 h-10 rounded-full">
                                    <div>
                                        <p class="font-bold">{{ user.name }}</p>
                                    </div>
                                    <div>
                                        <p>{{ user.coins }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-else>加载中...</p>
                    </div>
                </transition>
            </div>
        </div>
    </div>

</template>

<script>
import scrollDownIcon from "@/assets/scrollDown.svg"
import emojiSelector from "@/assets/emojiSelector.svg"
import imageUploder from "@/assets/imageUploader.svg"
import { debounce } from "lodash";
import Compressorjs from 'compressorjs'
import { emojis, formatTime, imageSource, replaceEmojiWithImages } from "@/common/common";
import { uploadImage } from "@/openapi/image";
import { deleteMessage, getLiveUserList, getRecentMessageList, msgHeartbeat, sendMessage, getBadge, getNotification, openChat } from "@/openapi/message";
import { getCurrUserRank, getUserRankList, searchUser, getUserDetail } from "@/openapi/user";

const LOAD_MSG_MAX_COUNT = 1000;


export default {
    data() {
        return {
            onlineUsers: [],
            messages: [], // 从服务器获取的聊天记录
            inputMessage: '', // 输入框内容
            currentPage: 1,
            limit: 10, // 每页显示的消息数量,
            liveTimer: null,
            messageTimer: null,
            user: JSON.parse(localStorage.getItem('user')) || null,
            showEmojiPicker: false,
            isOnlineUsersOpen: this.isPcDevice(),
            rankUserList: [],
            heartbeatTimer: null,
            previewImageUrl: '',
            emojis,
            isLoadingMore: false,
            atUser: null,
            mentionSearchTerm: '', // 用于存储提及关键词
            mentionSuggestions: [], // 存储提及建议的数组
            isUploading: false, // 用于控制加载指示器的显示与隐藏
            myRankPostion: 0,
            showRetractButton: false,
            showPrompt: true,
            lastReadIndex: 0,
            unReadCount: 0,
            hasUnread: false,
            notification: "",

            showReplyButton: false,
            quotedMessage: null,

            // icon
            scrollDownIcon,
            emojiSelector,
            imageUploder,
        };
    },
    mounted() {
        getBadge().then(resp => {
            this.unReadCount = resp.data.data.unreadMessagesCount
            this.hasUnread = this.unReadCount > 0;
            // 这里不能取locaoStorage的值，可能是旧值
            getUserDetail().then(resp => {
                this.lastReadIndex = resp.data.data.lastReadId
                if (this.unReadCount > 0) {
                    // 计算出需要加载多少页
                    this.limit = Math.ceil(this.unReadCount / 10) * 10;
                    // 最多加载50页,防止内存溢出
                    this.limit = Math.min(this.limit, 500)
                }

                // 此处不自动跳转，传入参数 false
                this.fetchMessages(this.currentPage, false);
                this.messageTimer = setInterval(this.fetchMessages, 3000);
                this.fetchOnlineUsers();
                this.liveTimer = setInterval(this.fetchOnlineUsers, 3000); // 每3秒更新一次

                this.heartbeat();
                this.heartbeatTimer = setInterval(this.heartbeat, 60000);

                this.$nextTick(() => {
                    const previewableImages = document.querySelectorAll('.previewable');
                    previewableImages.forEach(img => {
                        img.addEventListener('click', (event) => {
                            event.preventDefault();
                            this.openModal(img.src);
                        });
                    });
                });
            })
        })
    },
    computed: {
        userList() {
            return this.isOnlineUsersOpen ? this.onlineUsers : [];
        },
    },
    methods: {
        scrollToLastUnread() {
            this.scrollToItem(this.lastReadIndex, true);
            this.hasUnread = false;
        },
        scrollToItem(index, needShowPrompt = false) {
            this.$nextTick(() => {
                const element = this.$refs.chatList.find(item => {
                    return item.id == index
                });
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    if (!needShowPrompt) {
                        return;
                    }
                    this.showPrompt = true;
                    setTimeout(() => {
                        this.showPrompt = false;
                    }, 2000); // 显示提示信息 2 秒
                }
            });

        },
        onImageSelect(event) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }

            if (!file.type.startsWith('image/')) {
                alert('请选择一个图片文件');
                return;
            }
            this.isUploading = true; // 开始上传，显示加载指示器

            this.compressImage(file)
                .then(res => {
                    this.uploadImage(res)
                })
                .catch(err => {
                    this.isUploading = false
                    alert(err)
                })

        },
        onMouseOver(index, self) {
            if (self) {
                // 自己的消息显示撤回、回复按钮
                this.messages[index].selfMsg = true;
            } else {
                // 其他人消息显示回复按钮
                this.messages[index].otherMsg = true;
            }
        },
        sendMessage(e) {
            // 图片可以直接发送
            if (e != null) e.preventDefault();
            // 发送消息到服务器并清空输入框
            if (this.inputMessage.trim()) {
                sendMessage(
                    { content: this.inputMessage, replyMessageId: this.quotedMessage?.id, atUser: this.atUser },
                    this.user?.id + '-' + this.inputMessage
                )
                    .then(() => {
                        this.mentionSuggestions = [];
                        this.inputMessage = "";
                        // 可以考虑在这里触发fetchMessages，以立即显示新发送的消息
                        this.fetchMessages(this.currentPage);
                        // 清空引用消息
                        this.clearQuotedMessage()
                    })
                    .catch((error) => {
                        console.error("Error sending message:", error);
                    })
            } else {
                alert("请输入消息内容");
            }
        },
        retractMessage(index) {
            const doRetract = confirm("确定要撤回这条消息吗？");
            if (!doRetract) return;
            // 你可以在这里调用API发送撤回请求
            deleteMessage(null, { id: this.messages[index].id })
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.messages.splice(index, 1);
                        this.fetchMessages(this.currentPage)
                    } else {
                        alert(resp.data.message)
                    }
                })
        },
        replyMessage(targetMsg) {
            this.quotedMessage = targetMsg;
            this.$refs.messageInput.focus();
        },
        clearQuotedMessage() {
            this.quotedMessage = null;
        },
        toggleOnlineUsers() {
            this.isOnlineUsersOpen = !this.isOnlineUsersOpen;
        },
        fetchOnlineUsers() {
            if (!this.isOnlineUsersOpen) return
            getLiveUserList()
                .then((response) => {
                    this.onlineUsers = response.data.data;
                })
                .catch((error) => {
                    console.error("Error fetching online users:", error);
                });
        },
        selectMention(selectedSuggestion) {
            const atIndex = this.inputMessage.lastIndexOf('@') + 1;
            const beforeText = this.inputMessage.substring(0, atIndex);
            const afterText = this.inputMessage.substring(atIndex + this.mentionSearchTerm.length);

            // 构建最终的输入文本，将提及项插入到@之后
            const finalText = `${beforeText}${selectedSuggestion.name} ` + afterText;
            this.inputMessage = finalText;
            this.mentionSuggestions = []; // 选择后清空提及建议列表
            this.mentionSearchTerm = ''; // 重置提及关键词
            this.atUser = selectedSuggestion.id
        },
        heartbeat() {
            msgHeartbeat();
        },
        async fetchMessages(page = 1, skip) {
            const response = await getRecentMessageList({ page: page, size: this.limit });
            const newMessages = response.data.data.list;
            this.messages = newMessages
            this.currentPage = page;
            if (skip) {
                this.scrollToLastUnread();
            }
        },
        async loadMoreMessages() {
            if (this.isLoadingMore) {
                return;
            }
            this.isLoadingMore = true;

            try {
                // limit 只在这里做变动，限制大小最大为 1000
                this.limit = this.limit + 10;
                if (this.limit > LOAD_MSG_MAX_COUNT) {
                    // limit 还用于其余拉取消息的请求
                    this.limit = LOAD_MSG_MAX_COUNT;
                    alert("当前加载信息过多，无法继续加载");
                    return;
                }
                this.fetchMessages();
            } catch (error) {
                console.error("Error loading more messages:", error);
            } finally {
                this.isLoadingMore = false;
            }
        },
        stopFetchingMessages() {
            if (this.intervalId) {
                clearInterval(this.intervalId); // 清除定时器
                this.intervalId = null; // 重置定时器ID
            }
        },
        handleInput(event) {
            const inputValue = event.target.value;
            const atIndex = inputValue.lastIndexOf('@');

            if (atIndex !== -1 && atIndex === inputValue.length - 1) {
                this.mentionSearchTerm = ''; // 清空提及关键词，准备接收新的输入
            } else if (atIndex !== -1) {
                this.mentionSearchTerm = inputValue.substring(atIndex + 1).trim(); // 提取提及关键词
                if (this.mentionSearchTerm) {
                    this.fetchMentionSuggestions(this.mentionSearchTerm); // 发起请求获取提及建议
                }
            } else {
                this.mentionSuggestions = []; // 如果没有@，清空提及建议
            }
        },
        getRankPostion() {
            getCurrUserRank().then(resp => this.myRankPostion = resp.data.data)
        },
        fetchMentionSuggestions(term) {
            searchUser({ "name": term })
                .then(resp => this.mentionSuggestions = resp.data.data)
        },
        getJustify(message) {
            return message.userId === this.user.id ? 'justify-end' : 'justify-start'
        },
        removeTimer() {
            clearInterval(this.liveTimer)
            clearInterval(this.messageTimer)
            clearInterval(this.heartbeatTimer)
        },
        open() {
            openChat()
        },
        handleKeyDown(event) {
            // 中文输入过程中不触发enter事件
            if (event.key === 'Enter' && !event.shiftKey && !event.isComposing) {
                event.preventDefault();
                this.sendMessage();
            }
        },
        addEmoji(emoji) {
            this.inputMessage += emoji;
            this.showEmojiPicker = false;
            // 聚焦输入框
            this.$refs.messageInput.focus();
        },
        isPcDevice() {
            // 这里使用了一种常见但简化的判断方式，通过屏幕宽度来区分
            // 你可以根据实际情况调整阈值或采用其他设备检测库
            return window.innerWidth >= 768;
        },
        compressImage(file) {
            return new Promise((resolve, reject) => {
                new Compressorjs(file, {
                    quality: 0.8,
                    // png大于2m转为jepg
                    convertSize: 2000000,
                    success(result) {
                        if (result.size < 1000000) {
                            const type = result.type;
                            const fileName = result.name;
                            const compressFile = new File([result], fileName, { type: type });
                            resolve(compressFile)
                        } else {
                            reject("图片过大，请压缩后上传")
                        }
                    },
                    error(err) {
                        console.err(err)
                        reject(err.message)
                    },
                });
            })
        },
        uploadImage(file) {
            uploadImage(file, imageSource.CHAT)
                .then(response => {
                    if (response.data.code != 200) {
                        alert("图片上传失败")
                        return
                    } else {
                        this.inputMessage = `<img src="${response.data.data}" class="inline-block" />`;
                        this.sendMessage()
                    }
                })
                .catch(error => {
                    console.error('图片上传失败', error);
                })
                .finally(() => {
                    this.isUploading = false; // 上传结束，隐藏加载指示器
                });
        },
        async loadRankUserList() {
            try {
                const response = await getUserRankList();
                this.rankUserList = response.data.data;
            } catch (error) {
                console.error('获取用户列表失败:', error);
            }
        },
        onScroll: debounce(function (e) {
            if ((e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight) {
                this.loadMoreMessages();
            }
        }, 200),
        replaceEmojiWithImages,
        formatTime,
        getNotification() {
            getNotification().then(resp => this.notification = resp.data.data)
        }
    },
    beforeMount() {
        this.stopFetchingMessages(); // 组件销毁前确保清除定时器
    },
    beforeUnmount() {
        this.removeTimer()
    },
    created() {
        this.getRankPostion()
        this.loadRankUserList()
        this.getNotification()
        // 这个顺序不能改， 这里会刷新最新的读消息偏移量，一定要放到最后open，否则就会覆盖为最新的lastReadIndex
        // this.open()
    }
};
</script>

<style>
.dialog-modal {
    width: calc(50% - 30px);
    height: calc((50% - 30px) * 2 / 3);
    margin: 15px;
    /* 增加15px的外边距，以抵消减去的30px */
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* ...保留原有的样式... */
.text-fit {
    font-size: clamp(1px, 10px, 18px);
    /* 设置字体大小的最小值、最大值和默认值 */
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出容器的文本 */
    text-overflow: ellipsis;
    /* 使用省略号表示被隐藏的文本 */
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all .3s ease;
}


.left-arrow {
    @apply top-1/2 transform -translate-y-1/2 left-[-10px];
    width: auto;
    height: auto;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid rgb(255, 0, 51);
}


.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.mention-suggestions {
    position: absolute;
    z-index: 100;
    list-style-type: none;
    padding: 0;
    margin-top: 2px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mention-suggestions li {
    padding: 5px 10px;
    cursor: pointer;
}

.mention-suggestions li:hover {
    background-color: #eee;
}

.prompt {
    position: absolute;
    background-color: yellow;
    padding: 10px;
    border: 1px solid black;
    z-index: 1000;
}

.blink {
    animation: blinker 1s linear infinite;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.last-read-indicator {
    @apply mt-4 mb-4 flex justify-center items-center w-full;
}

@keyframes scroll-text {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}
</style>