<template>

    <body class="bg-100 dark:bg-gray-300">
        <nav class="bg-white shadow-lg top-0 z-50 dark:bg-gray-500">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between h-16">
                    <div class="flex items-center">
                        <a href="/"
                            class="text-gray-800 dark:text-white hover:text-gray-900 font-semibold px-3 text-2xl sm:text-sm">bitfree</a>
                        <a v-if="isLoggedIn" href="post"
                            class="text-gray-800 dark:text-white hover:text-gray-900 px-3 py-2 rounded-md font-medium">帖子</a>
                        <a v-if="isLoggedIn" href="chat"
                            class="relative text-gray-800 dark:text-white hover:text-gray-900 px-3 py-2 rounded-md font-medium">
                            聊天
                            <span v-if="unreadMessagesCount > 0"
                                class="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-red-500 border-white rounded-full">
                                {{ unreadMessagesCount }}
                            </span>
                        </a>
                        <a v-if="isLoggedIn" href="video"
                            class="text-gray-800 dark:text-white hover:text-gray-900 px-3 py-2 rounded-md font-medium">视频</a>
                        <a v-if="isLoggedIn" href="question"
                            class="text-gray-800 dark:text-white hover:text-gray-900 px-3 py-2 rounded-md font-medium">题库</a>
                        <!-- <a v-if="isLoggedIn" href="activity"
                            class="text-gray-800 hover:text-gray-900 px-3 py-2 rounded-md font-medium">活动</a> -->
                        <a v-if="isLoggedIn" href="task"
                            class="relative text-gray-800 dark:text-white hover:text-gray-900 px-3 py-2 rounded-md font-medium">
                            <span v-if="isHot"
                                class="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-red-500 rounded-full">
                                hot
                            </span>
                            开发
                        </a>
                    </div>
                    <div class="hidden md:block justify-start">
                        <div class="ml-10 items-baseline space-x-4">
                            <a v-if="!isLoggedIn" href="register"
                                class="text-gray-800 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium">注册</a>
                            <button v-if="!isLoggedIn"
                                class="bg-black text-white rounded-full px-4 py-2 text-base font-medium"
                                @click="showLoginModal = !showLoginModal">
                                登录
                            </button>
                            <div @click="toggleDropdown" class="relative inline-block cursor-pointer">
                                <div v-if="isLoggedIn" to="/account"
                                    class="bg-gray-500 dark:bg-gray-800 text-white rounded px-4 py-2 text-base font-medium">
                                    我的
                                </div>
                                <transition name="fade">
                                    <div v-show="isDropdownVisible"
                                        class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10">
                                        <a href="/account?active=0"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            @click="closeDropdown">个人设置</a>
                                        <a href="/account?active=1"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            @click="closeDropdown">收藏帖子</a>
                                        <a href="/verify"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            @click="closeDropdown">题库审核</a>
                                        <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            @click="toggleDarkMode">切换模式</a>
                                    </div>
                                </transition>
                            </div>

                            <button v-if="isLoggedIn"
                                class="text-grey dark:text-white rounded px-4 py-2 text-base font-medium"
                                @click="logout">
                                退出
                            </button>
                        </div>
                    </div>
                    <div class="-mr-2 flex md:hidden">
                        <!-- Mobile menu button -->
                        <button type="button" @click="toggleNavbar"
                            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span class="sr-only">Open main menu</span>
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="md:hidden" v-if="isNavbarOpen">
                <a v-if="!isLoggedIn" href="register"
                    class="text-gray-800 hover:text-gray-900 px-3 py-2 rounded-md sm:text-sm font-medium">注册</a>
                <button v-if="!isLoggedIn" @click="showLoginModal = !showLoginModal"
                    class="text-gray-800 hover:text-gray-900 px-3 py-2 rounded-md sm:text-sm font-medium">登录</button>

                <div @click="toggleDropdown" class="relative inline-block cursor-pointer">
                    <div v-if="isLoggedIn" to="/account"
                        class="bg-gray-500 dark:bg-gray-800 text-white rounded px-4 py-2 text-base font-medium">
                        我的
                    </div>
                    <transition name="fade">
                        <div v-show="isDropdownVisible"
                            class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10">
                            <a href="/account?active=0" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                @click="closeDropdown">个人设置</a>
                            <a href="/account?active=1" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                @click="closeDropdown">收藏帖子</a>
                            <a href="/verify" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                @click="closeDropdown">题库审核</a>
                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                @click="toggleDarkMode">切换模式</a>
                        </div>
                    </transition>
                </div>
                <button v-if="isLoggedIn" class="text-grey dark:text-white rounded px-4 py-2 text-base font-medium"
                    @click="logout">
                    退出
                </button>
            </div>
        </nav>

        <!-- Rest of your content -->
        <router-view v-slot="{ Component }">
            <keep-alive :include="keepAlive">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </body>

    <transition name="modal">
        <div v-if="showLoginModal" class="fixed inset-0 z-50 bg-gray-800 opacity-100">
            <div class="fixed inset-0 z-51 flex justify-center items-center">
                <div class="w-screen max-w-md overflow-auto border-5">
                    <div class="bg-white rounded-lg p-8">
                        <h3 class="text-2xl font-medium mb-4">登录</h3>
                        <div class="mt-4">
                            <label for="email" class="block text-gray-700">邮箱</label>
                            <input placeholder="Email" type="email" id="email"
                                class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                :class="{ 'is-invalid': !validEmail }" v-model="email" :disabled="disabled" />
                            <p v-if="!validEmail" class="text-red-600 mt-1">请输入有效的邮箱地址</p>
                        </div>
                        <div class="mt-4">
                            <label for="password" class="block text-gray-700">密码</label>
                            <input type="password" placeholder="Password" id="password"
                                class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                :class="{ 'is-invalid': !validPassword }" :disabled="disabled" v-model="password" />
                            <p v-if="!validPassword" class="text-red-600 mt-1">密码不能为空</p>
                        </div>
                        <p v-if="errorMessage" class="error-message mt-5">{{ errorMessage }}</p>

                        <div v-if="!disabled" class="mt-4 flex items-center justify-between">
                            <button @click="showLoginModal = false"
                                class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
                                取消
                            </button>
                            <div class="flex items-center justify-end mt-4">
                                <button @click="resetPasswordModal = true"
                                    class="text-blue-500 underline hover:text-blue-700">
                                    忘记密码？
                                </button>
                            </div>
                            <button @click="submitForm" class="bg-blue-500 text-white font-bold py-2 px-4 rounded">
                                登录
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <transition name="modal">
        <div v-if="resetPasswordModal" class="fixed inset-0 z-50 bg-gray-800 opacity-100">
            <div class="fixed inset-0 z-51 flex justify-center items-center">
                <div class="w-screen max-w-md overflow-auto border-5">
                    <div class="bg-white rounded-lg p-8">
                        <h3 class="text-2xl font-medium mb-4">找回密码</h3>
                        <div class="mt-4">
                            <label for="email" class="block text-gray-700">邮箱</label>
                            <input placeholder="Email" type="email" id="email"
                                class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                :class="{ 'is-invalid': !validEmail }" v-model="email" :disabled="disabled" />
                            <p v-if="!validEmail" class="text-red-600 mt-1">请输入有效的邮箱地址</p>
                        </div>

                        <div v-if="!disabled" class="mt-4 flex items-center justify-between">
                            <button @click="resetPasswordModal = false"
                                class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
                                取消
                            </button>
                            <button @click="resetAccount" class="bg-blue-500 text-white font-bold py-2 px-4 rounded">
                                重置
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <transition name="modal">
        <div v-if="warnModal" class="fixed inset-0 z-50 bg-gray-800 opacity-100">
            <div class="fixed inset-0 z-51 flex justify-center items-center">
                <div class="bg-white rounded-3xl w-screen max-w-md overflow-auto border-5">
                    <div class="text-lg font-bold p-8 error-message mt-4">
                        重试次数过多，请稍后再试！
                    </div>
                    <button class="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md"
                        @click="warnModal = false; showLoginModal = false;">
                        知道了
                    </button>
                </div>
            </div>
        </div>
    </transition>

    <transition name="popup-fade">
        <div v-if="showRefreshPay"
            class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                <div v-if="level === 1" class="text-center mb-4">
                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_com.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_com.JPG" alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">{{ popupMessage }}</p>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_com.JPG" style="width:50%"
                                    alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_com.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">{{ popupMessage }}</p>
                    </div>
                </div>
                <div v-else class="text-center mb-4">

                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_ulti.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_ulti.JPG" alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">{{ popupMessage }}</p>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/payment/ali_xu_ulti.JPG"
                                    style="width:50%" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/payment/we_xu_ulti.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                        <p class="mt-6 font-bold text-red-500">{{ popupMessage }}</p>
                    </div>

                </div>
                <button @click="showRefreshPay = false"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
            </div>
        </div>
    </transition>

    <!-- 模态框 -->
    <transition name="fade">
        <div v-if="showUpdateInfoDialog" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg p-6 shadow-lg border-1 border-black-500">
                <p class="text-lg text-gray-800">请修改密码和完善个人信息</p>
                <button @click="showUpdateInfoDialog = false"
                    class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    确认
                </button>
            </div>
        </div>
    </transition>

</template>

<script>
import md5 from 'js-md5'
import Cookies from 'js-cookie';
import { getUserDetail, hadModifyPwd, userLogin, resetAccount } from './openapi/user';
import { getBadge } from './openapi/message';

function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name === decodeURIComponent(cookiePair[0].trim())) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export default {
    watch: {
        '$route'(to, from) {
            // 当路由变化时执行的逻辑
            if (from.fullPath == '/account' && !Cookies.get('token') || !localStorage.getItem('user')) {
                this.isLoggedIn = false
            }
        }
    },
    data() {
        return {
            isNavbarOpen: false, // 初始状态为关闭
            isActive: true,
            showLoginModal: false,
            email: '',
            password: '',
            errorMessage: '',
            disabled: false,
            isLoggedIn: !!getCookie("token"), // 检查"token" Cookie是否存在
            warnModal: false,
            resetPasswordModal: false,
            unreadMessagesCount: 0, // 未读消息数量
            showUpdateInfoDialog: false,
            isDropdownVisible: false,
            showRefreshPay: false,
            popupMessage: '',
            level: 0,
            isHot: false,
            isDarkMode: localStorage.getItem('theme') === 'dark',
            keepAlive: ["post", "video"]
        }
    },
    methods: {
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;

            if (this.isDarkMode) {
                document.documentElement.classList.add('dark');
                localStorage.setItem('theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('theme', 'light');
            }
        },
        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen; // 点击按钮时切换展开状态
        },
        async submitForm() {
            try {
                if (!this.email || !this.password) {
                    this.errorMessage = 'Email和密码不能为空';
                    return;
                }
                const resp = await userLogin({
                    email: this.email,
                    password: md5(this.password),
                });
                if (resp.data.code == 200) {
                    Cookies.set('token', resp.data.data, { expires: 7 }); // 这里假设token的有效期为7天
                    this.saveUserInfo()

                    this.errorMessage = ''; // 清除错误信息
                    this.showLoginModal = false
                    this.isLoggedIn = true
                    this.$router.push('/')
                    this.timerFetchNotification()

                    // 判断是否需要引导重置密码
                    const hadModifyPwdResp = await hadModifyPwd();
                    if (!hadModifyPwdResp.data.data) {
                        this.$router.push('account')
                        this.showUpdateInfoDialog = true
                    }
                } else {
                    this.errorMessage = resp.data.message;
                    if (resp.data.code === 503) {
                        this.disabled = true;
                        this.warnModal = true
                    }
                    if (resp.data.code === 4004) {
                        this.showLoginModal = false
                        this.level = Number(resp.data.data)
                        alert(resp.data.message)
                        this.popupMessage = "限时优惠续费 [请备注邮箱]"
                        this.showRefreshPay = true
                    }
                }

            } catch (error) {
                this.errorMessage = '登录失败，请稍后重试';
            }
        },
        logout() {
            // 删除所有Cookie
            for (let key in Cookies.get()) {
                Cookies.remove(key);
            }
            // 重置登录状态
            this.isLoggedIn = false;
            clearInterval(this.pollingInterval)
        },
        handleKeyDown(event) {
            // 检查是否按下了ESC键（键码27）
            if (event.keyCode === 27) {
                this.showLoginModal = false;
            }
        },
        async saveUserInfo() {
            try {
                const response = await getUserDetail();
                const userInfo = await response.data.data;
                // 将用户信息存储到localStorage
                localStorage.setItem('user', JSON.stringify(userInfo));
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        async resetAccount() {
            const resp = await resetAccount({
                email: this.email
            });
            if (resp.data.code == 200) {
                alert("重置密码邮件已发送，请查收！")
                return
            }
            alert(resp.data.message)
        },
        async timerFetchNotification() {
            try {
                const response = await getBadge();
                this.unreadMessagesCount = response.data.data.unreadMessagesCount;
                this.isHot = response.data.data.hot;
            } catch (error) {
                console.error('Error fetching unread messages count:', error);
            }
        },
        toggleDropdown() {
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        closeDropdown() {
            this.isDropdownVisible = false;
        }
    },
    async created() {
        if (!Cookies.get('token') || !localStorage.getItem('user')) this.isLoggedIn = false
        console.log("   __   _ __  ___   \n" +
            "  / /  (_) /_/ _/______ ___ \n" +
            " / _ \\/ / __/ _/ __/ -_) -_) \n" +
            "/_.__/_/\\__/_//_/  \\__/\\__/ ")
        console.log("别抓包了，赶快入坑吧～，这不比卖课好玩? doge")

        if (Cookies.get('token') && localStorage.getItem('user')) {
            const resp = await getUserDetail();
            if (resp.data.code == 4003) {
                this.isLoggedIn = false
                alert(resp.data.message);
                return
            }

            // 刷新user 
            const userInfo = await resp.data.data;
            // 将用户信息存储到localStorage
            localStorage.setItem('user', JSON.stringify(userInfo));

            this.timerFetchNotification()

            // 设置定时器，每5秒请求一次
            this.pollingInterval = setInterval(this.timerFetchNotification, 5000);

            if (!resp.data.data.needNotice) return;
            this.showRefreshPay = true;
            this.popupMessage = "本账号距离到期仅 " + resp.data.data.days + " 天! 限时优惠续费 [请备注邮箱]";
            this.level = resp.data.data.level
            this.canVerified = resp.data.data.canVerified
        }

    },
    computed: {
        validEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 邮箱正则表达式
            return emailPattern.test(this.email) && this.email !== "";
        },
        validPassword() {
            return this.password !== "";
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
        // 检查用户之前的选择
        if (localStorage.getItem('theme') === 'dark') {
            this.isDarkMode = true;
            document.documentElement.classList.add('dark');
        }
    },
    beforeUnmount() {
        // 组件销毁前清除定时器
        clearInterval(this.pollingInterval);
        document.removeEventListener('keydown', this.handleKeyDown);
    },
}


</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.badge {
    background-color: var(--badge-color, red);
    /* 如果需要动态改变背景色，可以在这里定义并传递变量 */
    color: white;
    border: 2px solid rgba(255, 255, 255, var(--border-opacity));
    /* 褪色边框效果 */
    padding: 0.2em;
    /* 确保内容周围有足够的空间 */
    min-width: 1em;
    /* 设置最小宽度，避免内容过多时尺寸过小 */
    min-height: 1em;
    font-size: var(--count-size);
    /* 动态字体大小，同时影响整体大小 */
    line-height: 1;
    /* 保持内容垂直居中 */
    border-radius: calc(var(--count-size) / 2);
    /* 让边框随文字大小变化 */
    transition: all 0.3s ease;
    /* 平滑过渡效果 */
}

.error-message {
    color: red;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.md-editor-preview {

    ol,
    li {
        list-style: disc !important;
    }
}
</style>
