import axios from "axios";

export async function getBadge() {
    return axios.get('/message/getBadge');
}

export async function sendMessage(body, uuid) {
    return axios.post('/message/sendMessage', { ...body, uuid });
}

export async function openChat() {
    return axios.post('/message/openChat');
}

export async function deleteMessage(body, params) {
    return axios.post('/message/deleteMessage', body, { params });
}

export async function getLiveUserList() {
    return axios.get('/message/getLiveUserList');
}

export async function msgHeartbeat() {
    return axios.get('/message/heartbeat');
}

export async function getRecentMessageList(params) {
    return axios.get('/message/getRecentList', { params });
}

export async function getNotification() {
    return axios.get('/message/notification');
}