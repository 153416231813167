import axios from "axios";
export async function getList(params) {
    return axios.get('/taskBoard/getTaskBoardList', {params});
}

export async function updateTask(params) {
    return axios.post('/taskBoard/updateTask', params);
}

export async function canVerify() {
    return axios.get('/taskBoard/canVerify');
}


