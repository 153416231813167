<template>
    <div class="flex justify-center bg-gray-200 min-h-screen p-4">
        <div class="w-full max-w-5xl border border-gray-300 rounded-lg p-6 bg-white shadow-md">
            <div v-if="loading" class="flex items-center justify-center h-96">
                <span class="text-xl text-gray-700">Loading...</span>
            </div>
            <div v-else class="border border-gray-300 rounded-lg p-6 bg-gray-50 dark:bg-slate-200">
                <div v-if="hasVideo(compiledMarkdown)" ref="videoContainer">
                    <div class="flex items-center justify-center h-full">
                        <vue-video-player v-if="videoSrcLoaded" ref="videoPlayer" class="video-js vjs-big-play-centered"
                            :options="playerOptions" @play="onPlay" @pause="onPause" @ended="onEnded" @error="onError"
                            @loadeddata="onLoadeddata" @ready="onReady"></vue-video-player>
                    </div>
                </div>
                <div v-else class="markdown-body">
                    <BitfreeMdPreview class="p-3" :md-text="markdownContent" />
                </div>
            </div>

            <div class="mt-6 flex justify-center">
                <div class="w-full rounded-lg bg-white shadow-sm p-4 flex flex-col dark:bg-gray-200">
                    <textarea v-model="replyContent"
                        class="w-full h-32 p-4 outline-none rounded-lg border border-gray-300 resize-none dark:bg-slate-200"
                        placeholder="输入评论，支持 markdown 格式"></textarea>
                    <div class="flex justify-between items-center mt-4">
                        <button @click="showEmojiPicker = true"
                            class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                            选择表情
                        </button>
                        <button @click="addReply(null)"
                            class="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600">
                            回复
                        </button>
                    </div>
                </div>
            </div>

            <!-- 表情选择弹窗 -->
            <div v-if="showEmojiPicker"
                class="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full relative dark:bg-slate-200">
                    <div class="flex justify-end">
                        <button @click="showEmojiPicker = false" type="button"
                            class="text-gray-400 hover:text-gray-900 focus:outline-none">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex flex-wrap mt-4">
                        <span v-for="emoji in emojis" :key="emoji" @click="addEmoji(emoji)">
                            <img :src="'static/emoji/' + emoji + '.webp'"
                                class="w-10 object-contain mr-2 mb-2 cursor-pointer" alt="emoji" />
                        </span>
                    </div>
                </div>
            </div>

            <div class="mt-8 bg-white">
                <h2 class="text-2xl mb-4 font-semibold">评论区</h2>
                <ul class="space-y-4">
                    <ReplyItem v-for="reply in replies" :key="reply.id" :reply="reply" @reply-added="getReplyList" />
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked';
import 'video.js/dist/video-js.css';
import ReplyItem from "./ReplyItem.vue";
import BitfreeMdPreview from './BitfreeMdPreview.vue';
import { emojis, formatTime } from '@/common/common';
import { getPostDetail, getPostReplyList, replyPost } from '@/openapi/post';
import { getFileById } from '@/openapi/file';
import { md5 } from 'js-md5';


export default {
    data() {
        return {
            markdownContent: "",
            compiledMarkdown: '',
            loading: true,
            id: null,
            replyContent: "",
            replies: [],
            playerOptions: {
                autoplay: false,
                controls: true,
                aspectRatio: '16:9',
                fluid: true,
                sources: [
                    {
                        type: 'video/mp4',
                        src: '',
                    },
                ],
                poster: '',
                language: 'zh-CN',
                playbackRates: [0.5, 1, 1.5, 2, 2.5, 3]
            },
            videoSrcLoaded: false, // Add this line
            showEmojiPicker: false,
            emojis
        };
    },
    components: {
        BitfreeMdPreview,
        ReplyItem
    },
    methods: {
        async fetchMarkdownContent() {
            const id = this.$route.query.id;
            try {
                const response = await getPostDetail({
                    id: id
                });
                this.loading = false;
                this.markdownContent = response.data.data.content;
                this.compileMarkdown();
            } catch (error) {
                console.error("Error fetching markdown:", error);
                this.loading = false;
            }
        },
        addEmoji(emoji) {
            this.replyContent += emoji;
        },
        formatTime,
        compileMarkdown() {
            this.compiledMarkdown = marked(this.markdownContent);
        },
        async getReplyList() {
            try {
                const response = await getPostReplyList({ id: this.$route.query.id });
                this.replies = response.data.data.map(reply => ({
                    ...reply,
                    showReplyInput: false,
                    replyContent: reply.replyContent,
                    replyId: reply.id
                }));
                this.replies = response.data.data
            } catch (error) {
                console.error("Error fetching replies:", error);
            }
        },
        addReply(replyId = null) {
            const replyContent = this.replyContent;
            replyPost(
                {
                    postId: this.$route.query.id,
                    replyContent: replyContent,
                    replyId: replyId
                },
                JSON.parse(localStorage.getItem("user"))?.id + '-' + md5(replyContent)
            ).then(() => {
                this.replyContent = "";
                this.getReplyList();
            }).catch((error) => {
                console.error("Error adding reply:", error);
            });
        },
        hasVideo(markdownText) {
            return markdownText.indexOf('fileId:') != -1;
        },
        onPlay() {
            console.log('视频开始播放');
        },
        onPause() {
            console.log('视频暂停');
        },
        onEnded() {
            console.log('视频播放结束');
        },
        onError(error) {
            console.error('视频播放错误:', error);
        },
        onLoadeddata() {
            console.log('视频数据加载完成');
        },
        onReady(player) {
            console.log('视频准备就绪', player);
        },
        async fetchMediaInfo() {
            const extractFileId = () => {
                const position = this.markdownContent.indexOf('fileId:');
                if (position !== -1) {
                    const start = position + 'fileId:'.length;
                    const end = this.markdownContent.indexOf(']', position);
                    const fileId = parseInt(this.markdownContent.substring(start, end), 10);
                    return fileId;
                }
                return null;
            };

            const fileId = extractFileId();
            if (fileId === null) {
                return;
            }

            try {
                const resp = await getFileById({ id: fileId });
                const fileType = resp.data.data.type;
                if (fileType == 1) {
                    this.playerOptions.sources[0].src = resp.data.data.url;
                    this.playerOptions.poster = resp.data.data.poster;
                    this.videoSrcLoaded = true;
                } else if (fileType == 2) {
                    const imageUrl = resp.data.data.url;
                    this.markdownContent = this.markdownContent.replace('[fileId:' + resp.data.data.id + ']', '<img style="width:100%;" src=' + imageUrl + ' />');
                }
            } catch (error) {
                console.error('Error fetching media info:', error);
            }
        }
    },
    async created() {
        await this.fetchMarkdownContent();
        await this.fetchMediaInfo();
        await this.getReplyList();
    }
};
</script>

<style scoped>
.markdown-body {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    font-size: 16px;
    text-align: left !important;
}

.vjs-big-play-centered .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
</style>